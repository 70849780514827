<template>
	<form
		@keydown="clearValidationError($event.target.name);"
		@change="clearValidationError($event.target.name);">
		<div class="justify-content-between align-items-end m-2 mb-3">
			<li class="list-group-item border-top-0 border-left-0 border-right-0 rounded-0 pb-2">
				<div class="row">
					<div class="col-12 col-md-6">
						<div class="form-group">
							<input-text
								:id="`${currentValidation}.first_name`"
								:label="translate('first_name')"
								:setter-value="form.first_name"
								:errors="validationErrors[`${currentValidation}.first_name`]"
								:required="true"
								type="text"
								@dataChanged="form.first_name = $event" />
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="form-group">
							<input-text
								:id="`${currentValidation}.last_name`"
								:label="translate('last_name')"
								:setter-value="form.last_name"
								:errors="validationErrors[`${currentValidation}.last_name`]"
								:required="true"
								type="text"
								@dataChanged="form.last_name = $event" />
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="form-group">
							<input-text
								:id="`email`"
								:label="translate('email')"
								:setter-value="userInfo.email"
								:errors="validationErrors[`email`]"
								:required="false"
								:max="maxEmailLength"
								type="text"
								@dataChanged="userInfo.email = $event" />
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="form-group">
							<label for="mobile_number">
								{{ translate('mobile_number') }}
							</label>
							<vue-tel-input
								:id="`mobile_number`"
								ref="mobileNumberInput"
								v-model="userInfo.mobile_number"
								:required="false"
								:label="translate('mobile_number')"
								:placeholder="translate('mobile_number')"
								:class="validationErrors[`mobile_number`] ? 'is-invalid' : ''"
								v-bind="bindProps"
								type="text"
								:name="`mobile_number`"
								:only-countries="[cartCountry.attributes.code]"
								:default-country="cartCountry.attributes.code"
								class="form-control rounded-1"
								@dataChanged="userInfo.mobile_number = $event" />
							<template v-if="validationErrors[`mobile_number`]">
								<span
									v-for="error in validationErrors[`mobile_number`]"
									:key="error"
									class="invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</div>
					</div>
				</div>
			</li>
		</div>
		<div class="d-flex justify-content-between align-items-end m-2 mb-3">
			<li class="list-group-item border-0 rounded-0 pb-0 pt-0">
				<div class="row">
					<div class="col-12">
						<label class="form-check-label h5">
							{{ translate('create_an_account') }}
						</label>
						<div class="form-check">
							<input
								id="createAccount"
								v-model="showCreateAccount"
								name="createAccount"
								class="form-check-input"
								type="checkbox">
							<label for="createAccount">
								{{ translate('create_an_account_detail') }}
							</label>
						</div>
					</div>
				</div>
				<div
					v-if="createAccount"
					class="row">
					<div class="col-12 col-md-6">
						<div class="form-group">
							<input-text
								:id="`username`"
								:label="translate('username')"
								:setter-value="userInfo.username"
								:errors="validationErrors[`username`]"
								:required="false"
								type="text"
								@dataChanged="userInfo.username = $event">
								<template slot="afterLabelInline">
									<span
										v-b-tooltip.hover.html.right
										:title="translate('username_used_for_replicated_site', { website: `${website}/${form.username || translate('username')}` })"
										class="text-muted small mx-2">
										<i class="fas fa-lg fa-info-circle" />
									</span>
								</template>
							</input-text>
						</div>
					</div>
				</div>
			</li>
		</div>
	</form>
</template>
<script>
import { VueTelInput } from 'vue-tel-input';
import { MAX_EMAIL_LENGTH } from '@/settings/Validations';
import {
	CustomCarts as customCartsMessages,
	PersonalInformation as personalInfoMessages,
} from '@/translations';
import InputText from '@/components/InputText';

export default {
	name: 'PersonalInformation',
	components: { VueTelInput, InputText },
	messages: [customCartsMessages, personalInfoMessages],
	props: {
		form: {
			type: Object,
			default: () => ({}),
		},
		userInfo: {
			type: Object,
			default: () => ({}),
		},
		cartCountry: {
			type: Object,
			default: () => ({}),
		},
		validationErrors: {
			type: Object,
			default: () => ({}),
		},
		createAccount: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			maxEmailLength: MAX_EMAIL_LENGTH,
			website: process.env.VUE_APP_WEBSITE,
			currentValidation: 'personal_information',
			bindProps: {
				mode: 'international',
				inputOptions: {
					showDialCode: true,
				},
			},
			showCreateAccount: false,
		};
	},
	watch: {
		showCreateAccount: {
			handler(value) {
				this.$emit('update:createAccount', value);
			},
			immediate: true,
		},
	},
	created() {
		this.showCreateAccount = this.createAccount;
	},
	methods: {
		clearValidationError(name) {
			this.$emit('clear-error', name);
		},
	},
};
</script>
