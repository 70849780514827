<template>
	<flow-info-section
		:title="title"
		active-color="dark"
		hide-edit
		hide-cancel
		override-edit
		no-inner-spacing>
		<slot />
	</flow-info-section>
</template>
<script>
import FlowInfoSection from '@/components/FlowInfo/Section';

export default {
	name: 'DefaultFlowInfoSection',
	components: { FlowInfoSection },
	props: {
		title: {
			type: String,
			default: '',
		},
	},
};
</script>
