<template>
	<div class="mx-4 my-3 px-2">
		<item-select
			id="select_products"
			:title="translate('select_products')"
			:placeholder="translate('select_products')"
			reset-after
			:options="options"
			:selected-item.sync="selectedProduct">
			<div class="row">
				<div class="col-12 col-md-7">
					<b-card class="h-100">
						<products-table
							:data="selectedProducts"
							:loading="productsLoading"
							:products.sync="order.products"
							@remove="handleRemove" />
					</b-card>
				</div>
				<div class="col-12 col-md-5">
					<b-card class="h-100 border-top-darken">
						<template>
							<b-card-text class="h-100">
								<b-row class="h-100">
									<b-col
										cols="12"
										class="h-100">
										<div
											v-if="addProductsLoading || cartTotalsLoading"
											class="h-100 d-flex justify-content-center align-items-center">
											<is-loading
												:loading="addProductsLoading || cartTotalsLoading"
												:loading-label="translate('loading')"
												:has-data="Object.keys(cartTotalsData).length > 0 && isTotalsStep"
												no-data-label=""
												class="mx-auto" />
										</div>
										<div
											v-if="cartTotalsData.attributes && !addProductsLoading && !cartTotalsLoading && isTotalsStep">
											<div
												v-if="!!cartTotalsData.attributes.products.length"
												class="px-2">
												<h4 class="text-center">
													{{ translate('order_details') }}
												</h4>
												<template v-for="(product, key) in cartTotalsData.attributes.products">
													<b-row
														:key="key"
														class="bg-primary py-1 mb-1 rounded">
														<b-col class="col-6 col-md-8">
															<div class="d-flex h-100 align-items-center">
																<p class="text-left py-1 mb-0">
																	<b>{{ product.qty }}</b> x <b>{{ translate(product.code_name) }}</b>
																</p>
															</div>
														</b-col>
														<b-col class="col-6 col-md-4">
															<div class="d-flex h-100 align-items-center justify-content-end">
																<p class="text-right py-2 mb-0">
																	<b>{{ product.sub_total }}</b>
																</p>
															</div>
														</b-col>
													</b-row>
												</template>
											</div>
											<div
												class="p-2">
												<template v-for="(value, key) in cartTotalsData.attributes.totals">
													<b-row
														:key="key"
														:class="positiveValues.includes(key) ? 'font-weight-bold' : ''"
														:style="{ color: positiveValues.includes(key) ? positiveColor : 'default' }">
														<b-col class="col-5">
															<p
																:style="smallTextTotals.includes(key) ? '' : 'font-size:1.1em;'"
																:class="{
																	'text-medium': smallTextTotals.includes(key),
																}"
																class="mb-2 text-left">
																{{ key == 'shipping' ? translate('shipping_and_handling') : translate(key) }}
																<discount-detail
																	v-if="key === 'discount'"
																	:id="`discount-widget`"
																	:discount-detail="discountDetail" />
															</p>
														</b-col>
														<b-col class="col-7">
															<p
																:style="smallTextTotals.includes(key) ? '' : 'font-size:1.1em;'"
																:class="{
																	'text-medium': smallTextTotals.includes(key),
																}"
																class="mb-2 text-right">
																{{ value }}
															</p>
														</b-col>
													</b-row>
												</template>
											</div>
											<div class="p-2">
												<b-row>
													<b-col class="col-4 text-nowrap">
														<h4 class="text-left">
															<b>{{ translate('total') }}</b>
														</h4>
													</b-col>
													<b-col class="col-8">
														<h4 class="text-right">
															<b>{{ cartTotalsData.attributes.total }}</b>
														</h4>
													</b-col>
												</b-row>
											</div>
											<free-shipping-progress
												v-if="freeShippingInfo"
												:status="freeShippingInfo.status"
												:current-amount="freeShippingInfo.current_amount"
												:remaining-amount="freeShippingInfo.remaining_amount"
												:free-shipping-at="freeShippingInfo.free_shipping_at" />
										</div>
										<div
											v-else-if="!(addProductsLoading || cartTotalsLoading)"
											v-show="['information', 'products'].includes(step)"
											class="d-flex justify-content-center align-items-center h-100"
											style="width: 100%;">
											<button
												:disabled="selectedProducts.length < 1"
												class="btn btn-primary btn-lg"
												@click="$emit('calculate-totals')">
												<span class="h4">{{ translate('calculate_totals') }}</span>
											</button>
										</div>
									</b-col>
								</b-row>
							</b-card-text>
						</template>
					</b-card>
				</div>
			</div>
		</item-select>
	</div>
</template>
<script>
import ProductsTable from './ProductsTable';
import ItemSelect from '@/components/ItemSelect';
import {
	Products as productMessages,
	CustomCarts as customCartsMessages,
} from '@/translations';
import { POSITIVE_COLOR, POSITIVE_VALUES } from '@/settings/Cart';
import FreeShippingProgress from '@/components/FreeShippingProgress/index.vue';

export default {
	name: 'ProductsInformation',
	components: {
		FreeShippingProgress,
		ProductsTable,
		ItemSelect,
	},
	messages: [productMessages, customCartsMessages],
	props: {
		order: {
			type: Object,
			default: () => ({}),
		},
		productsLoading: {
			type: Boolean,
			default: false,
		},
		addProductsLoading: {
			type: Boolean,
			default: false,
		},
		cartTotalsLoading: {
			type: Boolean,
			default: false,
		},
		cartTotalsData: {
			type: Object,
			default: () => ({}),
		},
		options: {
			type: Array,
			default: () => ([]),
		},
		allProducts: {
			type: Array,
			default: () => ([]),
		},
		selectedProducts: {
			type: Array,
			default: () => ([]),
		},
		step: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			positiveColor: POSITIVE_COLOR,
			positiveValues: POSITIVE_VALUES,
			selectedProduct: null,
		};
	},
	computed: {
		smallTextTotals() {
			try {
				return this.cartTotalsData.attributes.small_subtotals;
			} catch (error) {
				return {};
			}
		},
		freeShippingInfo() {
			try {
				return this.cartTotalsData.attributes.free_shipping_info;
			} catch (error) {
				return null;
			}
		},
		isTotalsStep() {
			return ['totals'].includes(this.step);
		},
	},
	watch: {
		selectedProduct(selected) {
			if (selected === null) return;
			this.selectedProducts.push(selected);
			const options = this.allProducts.filter((product) => !this.selectedProducts.map((msProduct) => msProduct.id)
				.includes(product.id));
			this.$emit('update:options', options);
			this.$emit('update:step', 'products');
		},
		order: {
			handler() {
				this.$emit('update:step', 'products');
			},
			deep: true,
		},
	},
	methods: {
		handleRemove(removed) {
			this.selectedProducts.splice(this.selectedProducts.findIndex((product) => product.id === removed.id), 1);
			const options = this.allProducts.filter((product) => removed.id === product.id || !this.selectedProducts.map((msProduct) => msProduct.id)
				.includes(product.id));
			this.$emit('update:options', options);
			this.$emit('update:step', 'products');
		},
	},
};
</script>
