<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div
			v-if="!canCreateCart && !canCreateCartLoading"
			class="row mb-1">
			<div class="col">
				<div class="alert alert-danger w-100 pl-1">
					<div class="row no-gutters">
						<div
							class="col-auto d-flex justify-content-center my-auto"
							style="width: 40px;">
							<i class="fas fa-info mr-1" />
						</div>
						<div class="col">
							<span>{{ translate('your_account_is_inactive_no_link', { points: pointsToActivate }) }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else-if="canCreateCart">
			<flow-info-group class="my-3">
				<default-flow-info-section :title="`1. ${translate('country_information')}`">
					<div class="row mx-2 my-3">
						<div
							class="d-flex justify-content-center align-items-center"
							style="width: 100%;">
							<is-loading :loading="countriesLoading" />
						</div>
						<template v-if="!countriesLoading">
							<div
								id="countries-item-select"
								class="col-xs-12 col-sm-12 col-md-10">
								<label
									for="countries"
									class="label"
									style="float: left;">{{ translate('country') }}
									<span class="text-danger">*</span></label>
								<item-select
									id="countries"
									class="pt-2"
									:placeholder="translate('select_country')"
									:options="countriesItems"
									:selected-item.sync="countryItem"
									:disabled="hasCountrySelected" />
								<p
									class="mx-1 pt-0 text-muted small">
									*{{ translate('country_message') }}
								</p>
							</div>
							<div
								class="col-xs-12 col-sm-12 col-md-2 text-right"
								:class="!['xs', 'sm'].includes(windowWidth) ? 'pt-4 mt-1' : ''">
								<b-button
									style="border-width: thick; width: inherit;"
									:disabled="countryItem == null"
									variant="primary"
									@click="selectCountry">
									{{ translate(hasCountrySelected ? 'change' : 'select') }}
								</b-button>
							</div>
						</template>
					</div>
				</default-flow-info-section>
				<div v-if="hasCountrySelected && !countriesLoading">
					<default-flow-info-section :title="`2. ${translate('user_information')}`">
						<personal-information
							:form="form"
							:user-info="userInfo"
							:cart-country="cartCountry"
							:validation-errors="validationErrors"
							:create-account.sync="createAccount"
							@clear-error="clearValidationError" />
					</default-flow-info-section>
					<default-flow-info-section :title="`3. ${translate('address_information')}`">
						<shipping-information
							:address-form="addressForm"
							:cart-country-code="cartCountryCode"
							:validation-errors="validationErrors"
							@clear-error="clearValidationError" />
					</default-flow-info-section>
					<default-flow-info-section :title="`4. ${translate('products_information')}`">
						<products-information
							:order="order"
							:products-loading="products.data.loading"
							:add-products-loading="addProductsLoading"
							:cart-totals-loading="cartTotalsLoading"
							:cart-totals-data="cartTotalsData"
							:options.sync="productOptions"
							:all-products="allProducts"
							:selected-products.sync="selectedProducts"
							:step.sync="step"
							@calculate-totals="createCustomCart" />
					</default-flow-info-section>
				</div>
			</flow-info-group>
			<div class="d-flex justify-content-end align-items-center my-3">
				<input
					:value="translate('save_custom_cart')"
					:disabled="validateButtonDisabled"
					class="btn btn-primary mr-1 bg-primary"
					type="button"
					@click="createCustomCart">
			</div>
		</div>
		<is-loading :loading="canCreateCartLoading" />
	</div>
</template>
<script>
import Products from '@/util/Products';
import CustomCarts from '@/util/CustomCarts';
import {
	CustomCarts as customCartsMessages,
	Products as productMessages,
	Validations,
	Countries as countriesMessages,
} from '@/translations';
import DefaultFlowInfoSection from '@/components/DefaultFlowInfoSection';
import PersonalInformation from './components/PersonalInformation';
import ShippingInformation from './components/ShippingInformation';
import ProductsInformation from './components/ProductsInformation';
import FlowInfoGroup from '@/components/FlowInfo/Group';
import ItemSelect from '@/components/ItemSelect';
import {
	BLACKLIST_NON_SELECTABLE as blacklistNonSelectable,
} from '@/settings/ManualOrder';
import Country from '@/util/Country';
import CommonMix from './mixins/Common';
import WindowSizes from '@/mixins/WindowSizes';
import Cart from '@/util/Cart';
import { POINTS_TO_ACTIVATE } from '@/settings/Points';

export default {
	name: 'GenerateCart',
	components: {
		DefaultFlowInfoSection, ItemSelect, PersonalInformation, ShippingInformation, ProductsInformation, FlowInfoGroup,
	},
	mixins: [CommonMix, WindowSizes],
	messages: [productMessages, customCartsMessages, Validations, countriesMessages],
	data() {
		return {
			countries: new Country(),
			countriesItems: [],
			form: {
				first_name: '',
				last_name: '',
			},
			createAccount: false,
			addressForm: {
				address: '',
				address2: '',
				postcode: '',
				city: '',
				region_id: '',
				signature_required: false,
				override_validation: true,
			},
			userInfo: {
				email: '',
				mobile_number: '',
				username: '',
			},
			customCart: new CustomCarts(),
			step: 'country',
			alert: new this.$Alert(),
			products: new Products(),
			allProducts: [],
			countryItem: null,
			cartCountry: null,
			hasCountrySelected: false,
			order: {
				products: {},
			},
			productOptions: [],
			blacklistNonSelectable,
			selectedProducts: [],
			cart: new Cart(),
			addCartProduct: new Cart(),
			removeCart: new Cart(),
			pointsToActivate: POINTS_TO_ACTIVATE,
		};
	},
	computed: {
		countriesLoading() {
			return !!this.countries.data.loading;
		},
		addProductsLoading() {
			return !!this.addCartProduct.data.loading;
		},
		cartTotalsLoading() {
			return !!this.cart.data.loading;
		},
		cartTotalsData() {
			try {
				const { data } = this.cart.data.response.data;
				return data;
			} catch (error) {
				return {};
			}
		},
		cartCountryCode() {
			return this.cartCountry.attributes.code;
		},
		cartId() {
			try {
				return this.customCart.data.response.data.response.cart_id;
			} catch (error) {
				return '';
			}
		},
		validateButtonDisabled() {
			return !['totals'].includes(this.step)
				|| this.addProductsLoading
				|| this.cartTotalsLoading
				|| !this.hasCountrySelected;
		},
	},
	watch: {
		language() {
			this.productOptions.forEach((item) => {
				item.text = item.attributes.name;
			});
		},
		createAccount() {
			this.step = 'products';
		},
	},
	mounted() {
		this.canSponsorCheckout();
		this.getAllCountries();
	},
	methods: {
		getProducts() {
			this.products.getAllProducts({ country: this.cartCountryCode, custom_cart: 1 }).then((response) => {
				this.allProducts = response
					.filter((item) => !this.blacklistNonSelectable.find((sku) => sku === item.attributes.sku))
					.map((item) => ({
						...item,
						value: item.attributes.sku,
						text: item.attributes.name,
					}));
				this.productOptions = [...this.allProducts];
			});
		},
		getAllCountries() {
			this.countries.getCountries({ customCarts: true }).then((response) => {
				this.countriesItems = response
					.map((item) => ({
						...item,
						value: item.attributes.code,
						text: this.translate(item.attributes.code.toLowerCase()),
					}));
			});
		},
		selectCountry() {
			if (!this.hasCountrySelected && this.cartCountry !== this.countryItem) {
				this.cartCountry = this.countryItem;
				this.removeUsedCart();
				this.getProducts();
				this.setDefaultValues();
				this.step = 'information';
			} else if (this.hasCountrySelected && this.cartCountry === this.countryItem) {
				this.step = 'products';
			}
			this.hasCountrySelected = !this.hasCountrySelected;
		},
		addCartProducts(cartId, products) {
			const payload = {
				products,
				replace_qty: 1,
				is_custom_cart: 1,
				country: this.cartCountryCode,
			};
			this.addCartProduct.addCartProducts(cartId, payload).then(() => {
				this.cart.getCart(cartId, { customer_registration: this.createAccount ? 1 : 0 }, 'custom_cart');
				this.$emit('update:step', 'totals');
			});
		},
		createCustomCart() {
			if (this.step === 'products') {
				this.removeUsedCart();
				this.customCart.createCart(this.cartCountryCode).then(() => {
					this.addCartProducts(this.cartId, this.order.products);
					this.step = 'totals';
				});
			} else if (this.step === 'totals') {
				this.alert.loading(this.translate('loading_title'), this.translate('loading_text'), {
					allowEscapeKey: false,
					allowOutsideClick: false,
				});
				const payload = {
					country_code: this.cartCountryCode,
					cart_id: this.cartId,
					personal_information: { ...this.form },
					create_account: this.createAccount,
					shipping_information: { shipping_address: { ...this.addressForm, country_code: this.cartCountryCode } },
					...this.userInfo,
					products: this.order.products,
				};

				if (!this.createAccount) {
					payload.username = '';
				}

				this.validateStep(payload).then((response) => {
					const { url } = response.response;
					const style = getComputedStyle(document.body);
					const urlSize = ['xs', 'sm'].includes(this.windowWidth) ? "style='font-size: 1.25rem'" : '';
					const config = {
						config: {
							icon: 'success',
							allowEscapeKey: false,
							allowOutsideClick: false,
							showLoaderOnConfirm: true,
							showCancelButton: true,
							cancelButtonColor: style.getPropertyValue('--gray'),
							confirmButtonColor: style.getPropertyValue('--primary'),
							cancelButtonText: this.translate('close'),
							confirmButtonText: `<i class="fa fa-clipboard mr-3"></i>${ this.translate('copy') }`,
						},
					};
					this.alert.confirmation(
						`<div class='text-small font-weight-bold' ${urlSize}>${url}</div>`,
						this.translate('link_generated_message'),
						config,
						true,
					).then(() => {
						this.$clipboard(url);
						this.alert.toast('success', this.translate('link_copied'));
					}).catch(() => {
					}).finally(() => {
						if (this.$route.name !== 'CustomCartsActive') {
							this.$router.push({ name: 'CustomCartsActive' }).catch(() => {});
						}
					});
				}).catch(() => {
					const productError = this.validator?.errors?.errors?.products ?? [];
					if (productError.length) {
						this.alert.toast('error', productError[0], { timer: 6000 });
					} else {
						this.alert.close();
					}
				});
			}
		},
		setDefaultValues() {
			this.userInfo.mobile_number = '';
			this.addressForm.region_id = '';
			this.selectedProducts = [];
			this.customCart = new CustomCarts();
			this.cart = new Cart();
		},
		removeUsedCart() {
			if (this.cartId !== '') {
				this.removeCart.removeCart(this.cartId);
			}
		},
	},
};
</script>
<style>
	/* Not scoped due to it needing to affect the style of a child component, so using an ID instead */
	#countries-item-select .form-group {
		margin-bottom: 0.25rem;
	}
</style>
