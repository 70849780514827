<template>
	<div
		:class="{
			'card': status === 'free_shipping_not_reached',
			'w-100 mb-0 p-0': true,
		}">
		<div
			v-if="status === 'free_shipping_not_reached'"
			class="card-body p-1 m-0">
			<div class="text-dark">
				<div class="row no-gutters">
					<div
						class="col">
						<div class="row no-gutters align-items-center mb-1">
							<div class="col mx-2">
								<b-progress
									:max="freeShippingAt.amount"
									variant="primary"
									height="3em"
									animated
									striped
									class="mt-auto">
									<b-progress-bar :value="currentAmount.amount">
										<strong style="width: 100%; position: absolute; font-size: 0.875rem">
											<span class="text-dark bg-light h-100 rounded px-3">
												{{ translate('add_amount_more', { amount: remainingAmount.formatted_amount }) }}
											</span>
										</strong>
									</b-progress-bar>
								</b-progress>
							</div>
							<div class="col-auto">
								<img
									:src="`${S3_PATH}/assets/cart/${themeName}/free_shipping_35x35.png`"
									style="aspect-ratio: 1/1"
									width="35"
									height="35"
									class="img-fluid"
									alt="Free Shipping Icon">
							</div>
						</div>
						<div class="row no-gutters">
							<div class="col d-flex align-items-center">
								<div class="d-block w-100 px-2">
									<div
										class="row no-gutters w-100 justify-content-center">
										<div class="col-auto text-center">
											<span class="text-medium font-weight-bold">
												{{ translate('amount_to_free_shipping') }}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else-if="status === 'free_shipping_reached'"
			class="text-center">
			<img
				:src="`${S3_PATH}/assets/cart/${themeName}/free_shipping_250x40.png`"
				style="aspect-ratio: 250/40; max-width: 100%"
				width="250"
				height="40"
				class="img-fluid"
				alt="Free Shipping Banner">
		</div>
	</div>
</template>
<script>
import { Purchase } from '@/translations';
import { S3_PATH } from '@/settings/Images';

export default {
	name: 'FreeShippingProgress',
	messages: [Purchase],
	props: {
		currentAmount: {
			type: Object,
			default: () => ({}),
		},
		remainingAmount: {
			type: Object,
			default: () => ({}),
		},
		freeShippingAt: {
			type: Object,
			default: () => ({}),
		},
		status: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			themeName: process.env.VUE_APP_THEME,
			S3_PATH,
		};
	},
};
</script>
