<template>
	<div class="row mx-3 my-3">
		<address-country-form
			:address="ADDRESSES.shipping"
			:form-import="addressForm"
			:errors-import="validationErrors"
			:country-code-import="cartCountryCode"
			:display-cancel-button="false"
			:display-goback-button="false"
			:display-submit-button="false"
			hide-country-field
			hide-name-fields />
	</div>
</template>
<script>
import Country from '@/util/Country';
import {
	Addresses as addressesMessages,
} from '@/translations';
import AddressCountryForm from '@/components/AddressCountryForm';
import { ADDRESSES } from '@/settings/AddressBook';

export default {
	name: 'ShippingInformation',
	components: { AddressCountryForm },
	messages: [addressesMessages],
	props: {
		addressForm: {
			type: Object,
			default: () => ({}),
		},
		cartCountryCode: {
			type: String,
			default: '',
		},
		validationErrors: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			currentValidation: 'shipping_information',
			stateData: new Country(),
			ADDRESSES,
		};
	},
	computed: {
		states() {
			try {
				return this.stateData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		hasState() {
			return !!this.states.length;
		},
		statesLoading() {
			return this.stateData.data.loading;
		},
	},
	created() {
		this.stateData.getStates(this.cartCountryCode);
	},
	methods: {
		clearValidationError(name) {
			this.$emit('clear-error', name);
		},
	},
};
</script>
