import {
	GET_DISTRIBUTORS_PROFILE,
	GET_DISTRIBUTORS_ACCOUNT_INFORMATION,
	UPDATE_ACCOUNT_INFO,
	GET_DISTRIBUTOR_CAN_CHECKOUT,
	GET_OFFICIAL_DOCUMENT,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class DistributorsInformation {
	constructor() {
		this.data = new Req();
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	getProfile(userId) {
		const { method, endpoint } = GET_DISTRIBUTORS_PROFILE;
		return this.data[method](endpoint(userId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getInformation(userId) {
		const { method, endpoint } = GET_DISTRIBUTORS_ACCOUNT_INFORMATION;
		return this.data[method](endpoint(userId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateAccountInfo(userId, payload) {
		const { method, endpoint } = UPDATE_ACCOUNT_INFO;
		return this.data[method](endpoint(userId), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	canCheckout() {
		const { method, endpoint } = GET_DISTRIBUTOR_CAN_CHECKOUT;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getOfficialDocument(document) {
		const { method, endpoint } = GET_OFFICIAL_DOCUMENT;
		const queryParams = Req.setFilters({ document });
		return this.data[method](endpoint(queryParams)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default DistributorsInformation;
