import CustomCarts from '@/util/CustomCarts';
import DistributorInformation from '@/util/DistributorsInformation';

export default {
	data() {
		return {
			validator: new CustomCarts(),
			distributorInformation: new DistributorInformation(),
		};
	},
	computed: {
		validating() {
			try {
				return this.validator.data.loading;
			} catch (error) {
				return false;
			}
		},
		validationErrors() {
			try {
				return this.validator.errors.errors;
			} catch (error) {
				return [];
			}
		},
		canCreateCart() {
			try {
				return this.distributorInformation.data.response.data.response.can_checkout;
			} catch (error) {
				return false;
			}
		},
		canCreateCartLoading() {
			return !!this.distributorInformation.data.loading;
		},
	},
	methods: {
		validateStep(payload) {
			if (this.validating) {
				return null;
			}
			return this.validator.createCustomCart(payload)
				.then((response) => response)
				.catch((error) => Promise.reject(error));
		},
		clearValidationError(field) {
			this.validator.errors.clear(field);
		},
		canSponsorCheckout() {
			this.distributorInformation.canCheckout().catch(() => {});
		},
	},
};
